<template>
  <div class="grid-x">
    <div
      class="cell small-12 medium-6"
      v-for="category in relatedCategories"
      :key="category.id"
      :style="{
        backgroundColor: category.colour,
        color: category.textColour,
      }"
    >
    <router-link class="related" :to="{ name: 'wine-category', params: {slug: category.slug} }">
      <div class="text">
        <p class="text-label">Explore</p>
        <p class="name">{{ category.name }}</p>
      </div>
      <v-image class="bottle -one" :class="{['-'+category.related.bottlePosition]: true, ['-' + category.packagingType]: true}" v-if="category.related.bottles[0] && category.related.bottles[0].bottle" :image="category.related.bottles[0].bottle"/>
      <v-image class="bottle -two" :class="{['-'+category.related.bottlePosition]: true, ['-' + category.packagingType]: true}" v-if="(category.related.bottles[1] && category.related.bottles[1].bottle) || (category.related.bottles[0] && category.related.bottles[0].bottle)" :image="category.related.bottles[1]?category.related.bottles[1].bottle:category.related.bottles[0].bottle"/>
    </router-link>
    </div>
  </div>
</template>

<script>
  import VImage from '@/components/Image.vue';

  export default {
    components: {
      VImage
    },
    props: {
      related: Array
    },
    computed: {
      relatedCategories() {
        let relatedCategories = [];
        this.related.forEach(related => {
          relatedCategories.push(this.$store.getters.getWineCategory(related));
        });
        return relatedCategories;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .related {
    padding-top: 45%;
    display: block;
    position: relative;
    overflow: hidden;
  }
  .text {
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
    &-label {
      font: 300 #{vw-relative(14, 320)}/1 Brown;
      margin-bottom: .2em;
      @include breakpoint(medium) {
        font-size: vw-relative(20);
      }
    }
  }
  .name {
    font: 300 #{vw-relative(19, 320)}/1 Brown;
    @include breakpoint(medium) {
      font-size: vw-relative(35);
    }
  }
  .bottle {
    position: absolute;
    width: 11%;
    height: auto;
    &.-box {
      width: 20%;
    }
    &.-one {
      bottom: 0;
      left: 0;
      &.-vertical {
        transform: translate(30%, 30%);
      }
      &.-horizontal {
        transform: translate(30%, 30%) rotate(90deg);
      }
      &.-angled {
        transform: translate(80%, 30%) rotate(-20deg);
      }
    }
    &.-two {
      top: 0;
      right: 0;
      &.-vertical {
        transform: translate(-30%, -30%) rotate(180deg);
      }
      &.-horizontal {
        transform: translate(-30%, -30%) rotate(-90deg);
      }
      &.-angled {
        transform: translate(-80%, -30%) rotate(-160deg);
      }
    }
  }
</style>
